import React, { useState, useEffect, useRef } from 'react';
// Bootstrap Components
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import './App.css';
import ProgressBar from 'react-bootstrap/ProgressBar'

export default function App() {
  const [currentPlayer, setCurrentPlayer] = useState('')
  const [currentTarget, setCurrentTarget] = useState('')
  const [p1maxhp, setP1MaxHp] = useState(0)
  const [p2maxhp, setP2MaxHp] = useState(0)
  const [p1currenthp, setP1CurrentHp] = useState(0)
  const [p2currenthp, setP2CurrentHp] = useState(0)
  const [logData, setLogData] = useState(null)

  const logScrollView = useRef(null)
  const [canFight, setCanFight] = useState(true)


  let search = window.location.search;
  let params = new URLSearchParams(search);
  let p1param = params.get('p1');
  let p2param = params.get('p2');


  useEffect(() => {
    if(!p1param) return;
    setCurrentPlayer(p1param)
    document.getElementById('p1n').value = p1param
    p1Adjust(p1param)
  },[p1param])

  useEffect(() => {
    if(!p2param) return;
    setCurrentTarget(p2param)
    document.getElementById('p2n').value = p2param
    p2Adjust(p2param)
  },[p2param])

  function p1Adjust(tgt) {
    switch(tgt.length) {
      case 0: document.getElementById('p1').style.fontSize = '2.0em';break;
      case 1: document.getElementById('p1').style.fontSize = '2.0em';break;
      case 7: document.getElementById('p1').style.fontSize = '1.9em';break;
      case 9: document.getElementById('p1').style.fontSize = '1.8em';break;
      case 11: document.getElementById('p1').style.fontSize = '1.7em';break;
      case 14: document.getElementById('p1').style.fontSize = '1.6em';break;
      case 19: document.getElementById('p1').style.fontSize = '1.5em';break;
      default: document.getElementById('p1').style.fontSize = '2.0em';break;
    }
    setCurrentPlayer(tgt)
  }

  function p2Adjust(tgt) {
    switch(tgt.length) {
      case 0: document.getElementById('p2').style.fontSize = '2.0em';break;
      case 1: document.getElementById('p2').style.fontSize = '2.0em';break;
      case 7: document.getElementById('p2').style.fontSize = '1.9em';break;
      case 9: document.getElementById('p2').style.fontSize = '1.8em';break;
      case 11: document.getElementById('p2').style.fontSize = '1.7em';break;
      case 14: document.getElementById('p2').style.fontSize = '1.6em';break;
      case 19: document.getElementById('p2').style.fontSize = '1.5em';break;
      default: document.getElementById('p1').style.fontSize = '2.0em';break;
    }
    setCurrentTarget(tgt)
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  async function Fight() {
    setCanFight(false)
    setP1CurrentHp(0)
    setP2CurrentHp(0)
    if(currentPlayer.length === 0 && currentTarget.length === 0) { return; }
    try {
      let res = await fetch('/preparefight', {
        method: 'post',
        headers: {
          'Accept':'application/json',
          'Content-Type':'application/json'
        },
        body: JSON.stringify({
          player1: currentPlayer,
          player2: currentTarget
        })
      })

      let result = await res.json()
      setP1MaxHp(result.p1def)
      setP1CurrentHp(result.p1def)
      setP2MaxHp(result.p2def)
      setP2CurrentHp(result.p2def)
      if(result && result.success) {
        try {
          let fres = await fetch('/fight', {
            method: 'post',
            headers: {
              'Accept':'application/json',
              'Content-Type':'application/json'
            },
            body: JSON.stringify({
              p1name: currentPlayer,
              p1atk: result.p1atk,
              p1def: result.p1def,
              p2name: currentTarget,
              p2atk: result.p2atk,
              p2def: result.p2def
            })
          })
          let fresult = await fres.json();
          // TODO: Fight Animation?
          let rCountList = Object.keys(fresult.rounds)
          let rCount = rCountList.length
          let i = 0
          let lDataTmp = []

          let p1dmg = 0;
          let p2dmg = 0;

          for(i = 0; i<rCount;i++) {
            let r = fresult.rounds[rCountList[i]]
            lDataTmp.push(r)
            logScrollView.current.scrollTop = 100000000000

            if(r.attacker === currentPlayer) {
              p1dmg += r.damage
            } else {
              p2dmg += r.damage
            }

            setLogData(lDataTmp)
            if(r.p1_hp < 0) {
              setP1CurrentHp(0)
            } else {
              setP1CurrentHp(r.p1_hp)
            }
            if(r.p2_hp < 0) {
              setP2CurrentHp(0)
            } else {
              setP2CurrentHp(r.p2_hp)
            }
            
            await sleep(100)
          }
          let finalRound = fresult.rounds[rCountList[rCount-1]]
          let matchResult = {}
          if(finalRound.attacker === currentPlayer) {
            // Winner is Player 1
            matchResult = {
              winner: finalRound.attacker,
              loser: currentTarget,
              p1damage: p1dmg,
              p2damage: p2dmg,
              roundCount: rCount
            }
          } else {
            // Winner is Player 2
            matchResult = {
              winner: finalRound.attacker,
              loser: currentPlayer,
              p1damage: p1dmg,
              p2damage: p2dmg,
              roundCount: rCount
            }
          }

          saveResult(matchResult)
          
        } catch(e) {
          console.log(e)
        }
      } else {
        alert(result.msg)
        setCanFight(true)
        return;
      }
      setCanFight(true)
    } catch(e) {
      console.log(e)
    }
  }

  // Saving of Results
  async function saveResult (matchResult) {
    try {
      // Send Battle Results to API
      let res = await fetch('/saveresult', {
        method: 'post',
        headers: {
          'Accept':'application/json',
          'Content-Type':'application/json'
        },
        body: JSON.stringify({
          data: matchResult
        })
      })

      let result = await res.json()
      if(result && result.success) {
        // It Worked
      } else {
        alert(result.msg)
      }
    }
    catch(e) {
      console.log(e)
    }
  }

  return (
    <>

      <Navbar sticky="top" bg="dark" variant="dark" expand="lg">
        <Navbar.Brand>RedBattler</Navbar.Brand>
        <Form inline>
          <FormControl id="p1n" type="text" placeholder="Your Reddit Name" className="mr-sm-2" onChange={(e) => p1Adjust(e.target.value)} />
          <FormControl id="p2n" type="text" placeholder="Hunt User" className="mr-sm-2" onChange={(e) => p2Adjust(e.target.value)}/>
          <Button disabled={!canFight} variant="outline-light" onClick={() => Fight()}>Fight</Button>
        </Form>
      </Navbar>
      <Container className="match-container">
        <div id="p1" className="current-player">{currentPlayer}<ProgressBar className="hpbar" aria-disabled={p1maxhp === 0 ? true : false} id="p1pb" max={p1maxhp} now={p1currenthp} label={p1currenthp === 0 ? '' : 'HP ' + p1currenthp} /></div>
        <div className="versus-image"><img src="./vs.png" width="30%" alt="versus"/></div>
        <div id="p2" className="current-target">{currentTarget}<ProgressBar className="hpbar" aria-disabled={p2maxhp === 0 ? true : false} id="p2pb" max={p2maxhp} now={p2currenthp} label={p2currenthp === 0 ? '' : 'HP ' + p2currenthp}/></div>
      </Container>
      <Container className="log-container">
        <div ref={logScrollView} className="log-data">
        {logData !== null ? logData.map((round, rdata) => (
                      <div className="log-entry"><span className="text_atk">{round.attacker}</span> Attacks for <span className="text_dmg">{round.damage} {round.isCrit ? 'CRITICAL' : ''}</span> Damage!</div>
                  )) : ''}
          
        </div>
      </Container>
    </>
  )
}
